.FooterContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  transition: 0.3s linear;
}

.FooterContent {
  width: 80vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: black;
  padding: 30px 0 30px 0;
  border-radius: 50px;
  box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.25);
}

.FooterContent p {
  margin: 0;
  font-family: Nunito;
  font-weight: bold;
  font-size: 16px;
  color: white;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.FooterIconContainer {
  position: relative;
}

.FooterIconContainer:hover .Tooltip {
  opacity: 1;
}

.Tooltip {
  position: absolute;
  left: 50%;
  top: -40px;
  opacity: 0;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  font-family: Nunito;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.FooterButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #3672FF;
  padding: 12px 15px 12px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.3s linear;
}

@media(max-width: 420px) {
  .FooterButtons {
    padding: 10px 12px 10px 12px;
  }

  .ButtonContainer {
    gap: 15px;
  }
}

.FooterButtons:hover {
  background-color: #6492FF;
  cursor: pointer;
}

.FooterButtons:active {
  background-color: #3D74F4;
  transition: 0s linear;
}
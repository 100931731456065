.ExperienceContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 15vh 0 15vh 0;
  background-color: white;
  border-top: 1px solid #888787;
  border-bottom: 1px solid #888787;
  border-radius: 50px;
}

.ExperienceContainer p {
  margin: 0;
  font-family: Nunito;
}

.Header {
  font-size: 24px;
  font-weight: bold;
}

.BoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 500px;
  background-color: #F1F1F1;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  text-decoration: none;
  transition: 0.1s linear;
}

.BoxContainer:hover {
  background-color: #D8D8D8;
}

.BoxContainer:active {
  transition: 0;
  background-color: #F1F1F1;
}

.BoxContainer::after {
  content: "";
  position: absolute;
  top: 12.5%; 
  right: -5px;
  height: 75%; 
  width: 5px;
  background-color: #517CEA;
  border-radius: 0 10px 10px 0;
  transition: 0.1s linear;
}

.BoxContainer:hover::after {
  width: 8px;
  right: -8px;
}

.ExperienceHeader {
  font-size: 18px;
  color: black;
}

.DateHeader {
  font-size: 16px;
  color: #545454;
}

.ExperienceContentHeader {
  font-size: 16px;
  color: #545454;
}

.DateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
}

.SubHeader {
  align-self: flex-start;
  color: #517CEA;
  font-size: 16px;
}


@media(max-width: 768px) {
  .BoxContainer {
    width: 80%;
  }

  .BoxContainer::after {
    display: none;
  }
}

@media(max-width: 420px) {
  .BoxContainer {
    width: 70%;
  }

  .ExperienceContainer {
    text-align: center;
  }

  .DateContainer {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  .SubHeader {
    align-self: center;
  }
}
.EducationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 15vh 0 5vh 0;
}

.EducationContainer p {
  margin: 0;
  font-family: Nunito;
}

.Header {
  font-size: 24px;
  font-weight: bold;
}

.SectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}

.SectionHeader {
  margin-top: 10px;
  font-size: 18px;
}

.SectionContent {
  font-size: 16px;
  color: #545454;
}

.LinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  color: #3672FF;
  text-decoration: none;
}

.LinkContainer:hover {
  text-decoration: underline;
}

.Icon {
  font-size: 16px;
  transition: 0.1s linear;
}

.LinkContainer:hover .Icon {
  transform: rotate(45deg);
}


@media(max-width: 600px) {
  .SectionContainer {
    width: 85%;
  }
}